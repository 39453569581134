/*
  DO NOT REMOVE ANY COMMENTS INT THIS FILE.
  THEY ARE USED IN THE BUILD PROCESS
*/

/* DEFAULTS FOR DEV */
const AD_TENENT_ID = "70a10233-a54f-4cb4-9521-201c6d049916";
const AD_CLIENT_ID = 'f5debf93-6935-439d-9bf2-bc8c17814f65';
const REDIRECT_URI = 'http://localhost:3000';
const POST_LOGOUT_REDIRECT_URI = 'http://localhost:3000';
const WEB_API_ENDPOINT = 'http://localhost:5000';
const CONTENT_API_ENDPOINT = 'http://localhost:3005';
const PUBLIC_WEB_URL = 'http://localhost:3001';
const INSIGHT_URL = 'https://app.powerbi.com/groups/me/apps?ctid=70a10233-a54f-4cb4-9521-201c6d049916';
const CTS_URL = 'https://www.google.com/maps';
const UPDATE_INFO_SUBJECT = '(Test) Account update request';
const GTM_ID = 'GTM-N6DCBKM';
const PAGE_SIZE = 3;
const NEWS_PAGE_SIZE = 4;
const SCOPE = 'f5debf93-6935-439d-9bf2-bc8c17814f65/Read'
/*******************/

const tenantId = "70a10233-a54f-4cb4-9521-201c6d049916";
const clientId = "f5debf93-6935-439d-9bf2-bc8c17814f65";
const redirectUri = "https://test-portal.hvccc.com.au";
const postLogoutRedirectUri = "https://test-www.hvccc.com.au";
const webApiEndpoint = "https://test-web-api.hvccc.com.au";
const contentApiEndpoint = "https://test-content-api.hvccc.com.au";
const publicWebUrl = "https://test-www.hvccc.com.au";
const insightUrl = "https://app.powerbi.com/groups/me/apps?ctid=70a10233-a54f-4cb4-9521-201c6d049916";
const ctsUrl = "http://test-cts.hvccc.com.au";
const updateInfoSubject = "(Test) Account update request";
const gtmId = "GTM-N6DCBKM";
const pageSize = 12;
const newsPageSize = 5;
const scope = "f5debf93-6935-439d-9bf2-bc8c17814f65/Read";
//This determines which reports will allow the browser to open, all other content types will download
const autoOpenReportTypes = ['application/pdf']

const generateApiUrl = (url) => `${webApiEndpoint}${url}`

module.exports = {
  tenantId,
  clientId,
  redirectUri,
  postLogoutRedirectUri,
  webApiEndpoint,
  contentApiEndpoint,
  publicWebUrl,
  insightUrl,
  ctsUrl,
  updateInfoSubject,
  gtmId,
  pageSize,
  newsPageSize,
  autoOpenReportTypes,
  scope,
  generateApiUrl
};
